<template>
    <addresses :companyId="$store.getters['master/company'].id"/>
</template>

<script>
import Addresses from "@/master/views/Home/company/Addresses";

export default {
    name: "AddressesDashboard",
    components: {
        'addresses': Addresses
    }
}
</script>

<style scoped>
</style>